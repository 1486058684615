<template>
  <div class="container">
    <div class="wrapper">
      <div class="onboarding-widget">
        <div class="onboarding-widget__title-block">
          <h2>Help</h2>
          <h3>
            Quick look at what you can do inside Dawn at present time 👇
          </h3>
        </div>
        <div>
          <div class="onboarding-widget__content-block">
            <h4>Personal Planner</h4>
            <div class="onboarding-widget__content-block__wrapper">
              <div class="onboarding-widget__content-block__wrapper__text">
                <p class="larger nomargin">
                  Plan work by simply dragging your tasks from your backlog. In
                  addition, set a time limit, which is designed to work for you
                  and your well-being.
                </p>
              </div>
              <div class="onboarding-widget__content-block__wrapper__image">
                <img src="@/assets/imgs/feature-ob-01.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="onboarding-widget__content-block">
            <h4>Mood Insider</h4>
            <div class="onboarding-widget__content-block__wrapper">
              <div class="onboarding-widget__content-block__wrapper__text">
                <p class="larger nomargin">
                  Track how your mood evolves overtime and receive suggestions
                  when we feel it's leaning a little too close towards unhealthy
                  fatigue
                </p>
              </div>
              <div class="onboarding-widget__content-block__wrapper__image">
                <img src="@/assets/imgs/feature-ob-02.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="onboarding-widget__content-block">
            <h4>Plan mindfully</h4>
            <div class="onboarding-widget__content-block__wrapper">
              <div class="onboarding-widget__content-block__wrapper__text">
                <p class="larger nomargin">
                  Plan work by simply dragging your tasks from your backlog. In
                  addition, set a time limit, which is designed to work for you
                  and your well-being.
                </p>
              </div>
              <div class="onboarding-widget__content-block__wrapper__image">
                <img src="@/assets/imgs/feature-ob-01.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Help"
};
</script>

<style lang="scss" scoped>
p.larger {
  font-family: GreycliffCF-Regular;
  font-size: 18px;
  line-height: 30px;
  margin-top: 24px;
}
.onboarding-widget {
  &__title-block {
    margin-bottom: 48px;
    padding: 0 56px;

    h3 {
      margin-top: 16px;
    }
    h5 {
      margin-bottom: 0;
    }
  }
  &__content-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding: 0 56px;
    width: 100%;

    &__wrapper {
      align-items: center;
      box-sizing: border-box;
      display: flex;

      &__text {
        margin-right: 16px;
        width: 60%;
      }
      &__image {
        // width: 40%;

        img {
          width: 180px;
        }
      }
    }
  }
}
</style>
